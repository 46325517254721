import { Game } from "@lobby/core/entities/game";
import { useTranslate } from "@lobby/ocb-intl";
import { useAuth } from "@shared/lib";
import { Link } from "@tanstack/react-router";
import clsx from "clsx";
import { FavouriteBtn } from "./favourite-btn";

interface IGameCardProps {
  imgSrc: string;
  title: string;
  provider: string;
  id: number;
  gameStringId: string;
  isFavourite: boolean;
}

export function GameCard({
  imgSrc,
  title,
  provider,
  id,
  gameStringId,
  isFavourite,
}: IGameCardProps) {
  const { $t } = useTranslate();
  const { isAuth } = useAuth();
  const favouriteMutation = Game.useFavouriteSetter();

  return (
    <div className="group relative snap-start overflow-hidden rounded-xs border-1 border-transparent bg-white hover:border-java lg:rounded-rounded dark:bg-ebony-clay">
      <div className="h-full">
        <div className="aspect-square bg-blue-bayoux">
          <img
            className="size-full object-contain"
            src={imgSrc}
            alt={title}
            loading="lazy"
            width="100%"
            height="100%"
          />
        </div>
        <div className="flex h-[2.3125rem] flex-col justify-center px-2 lg:h-[3.5rem] lg:px-2.5">
          <div className="font-medium text-3xs text-blue-bayoux lg:text-xs">{provider}</div>
          <div
            className="truncate font-semibold text-3xs lg:mt-1 lg:text-xs dark:text-white"
            title={title}
          >
            {title}
          </div>
        </div>
      </div>

      <Link
        className="absolute inset-0 opacity-0 lg:group-hover:opacity-100"
        to="/game/$gameId"
        search
        params={{
          gameId: gameStringId,
        }}
      >
        <div className="absolute bottom-0 flex h-[2.3125rem] w-full items-center justify-center bg-java font-bold text-ebony-clay text-xs uppercase lg:h-14 lg:text-base">
          {$t({
            defaultMessage: "play",
          })}
        </div>
      </Link>
      {isAuth && (
        <FavouriteBtn
          className={clsx(
            "absolute top-1.5 right-1.5 mobile-only:hidden lg:top-2 lg:right-2",
            isFavourite ? "visible" : "invisible group-hover:visible",
          )}
          isFavourite={isFavourite}
          onClick={() => favouriteMutation.mutate({ gameId: id, isFavourite: !isFavourite })}
        />
      )}
      {favouriteMutation.isPending && (
        <div className="absolute inset-0 bg-white bg-opacity-60 dark:bg-bright-gray dark:bg-opacity-60" />
      )}
    </div>
  );
}
